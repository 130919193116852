import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { auth, onAuthStateChanged } from './firebase';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import './App.css';
import NavBar from './NavBar';
import Header from './Header';
import RecentActivities from './RecentActivities';
import WearablesSummary from './WearablesSummary';
import GoalTracking from './GoalTracking';
import Dashboard from './Dashboard';
import Login from './Login';
import ProfileSettings from './ProfileSettings';
import Modal from './Modal';
import TypeformQuestions from './TypeformQuestions';
import Upload from './Upload';
import PrivateRoute from './PrivateRoute';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [userAnswers, setUserAnswers] = useState(() => {
    return JSON.parse(localStorage.getItem('userAnswers')) || null;
  });

  const [userData, setUserData] = useState({
    name: 'Richard Sanders',
    profilePicture: 'https://lh3.googleusercontent.com/a/ACg8ocKiPs32hPHnXh-nXEgwolBfYLIUYy_t5NCwWTKmx0zC5yFOrpZK=s96-c',
    profileType: 'Health Enthusiast',
    totalDistance: 120,
    totalTime: 56,
    totalCalories: 34000,
  });

  const [goals, setGoals] = useState({
    steps: { goal: 10000, current: 9152 },
    sleep: { goal: 8, current: 6.5 },
    distance: { goal: 50, current: 10 },
    calories: { goal: 2000, current: 400 },
  });

  const [loading, setLoading] = useState(true);
  const [activityData, setActivityData] = useState(null);

  // Check authentication state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
        setUserData((prevData) => ({ ...prevData, email: user.email }));
      } else {
        setIsLoggedIn(false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  // Check for user answers and show modal if needed
  // useEffect(() => {
  //   if (isLoggedIn && !userAnswers) {
  //     setShowModal(true);
  //   }
  // }, [isLoggedIn, userAnswers]);

  // Fetch activity data if logged in
  useEffect(() => {
    if (isLoggedIn) {
      fetch('/data/garmin/af_celsius_2_28_2024.json')
        .then(response => response.json())
        .then(data => setActivityData(data))
        .catch(error => console.error('Error loading JSON:', error));
    }
  }, [isLoggedIn]);

  // Show loading state while checking auth
  if (loading) {
    return <div>Loading...</div>;
  }

  const handleLogin = (user) => {
    setUserData((prevData) => ({ ...prevData, email: user.email }));
    setTimeout(() => {
      setIsLoggedIn(true);
    }, 100);
  };

  const handleSubmitTypeform = (userAnswersFromModal, questions) => {
    const formattedAnswers = questions.map((question) => ({
      questionTitle: question.title,
      answer: userAnswersFromModal[question.id] || '',
    }));

    setUserAnswers(formattedAnswers);
    localStorage.setItem('userAnswers', JSON.stringify(formattedAnswers));
    setShowModal(false);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('userAnswers');
  };

  const handleSaveProfile = (updatedUser) => {
    setUserData((prevData) => ({
      ...prevData,
      name: updatedUser.name,
      profileType: updatedUser.profileType,
      profilePicture: updatedUser.profilePicture || prevData.profilePicture,
    }));
  };

  const handleSaveGoals = (updatedGoals) => {
    setGoals(updatedGoals);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Router>
      <div className="App">
        {isLoggedIn && <NavBar user={userData} onLogout={handleLogout} />}
        <Routes>
          {/* Redirect to /dashboard if already logged in */}
          <Route path="/" element={isLoggedIn ? <Navigate to="/dashboard" /> : <Login onLogin={handleLogin} />} />
          {/* Protected routes that require login */}
          <Route
            path="/dashboard"
            element={
              <PrivateRoute isLoggedIn={isLoggedIn}>
                <Header user={userData} totalActivities={5} activeDays={[30, 1, 3, 7, 15]} />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <RecentActivities />
                  <GoalTracking goals={goals} />
                  <WearablesSummary activityData={activityData} />
                </div>
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute isLoggedIn={isLoggedIn}>
                <ProfileSettings
                  user={userData}
                  userAnswers={userAnswers}
                  onSave={handleSaveProfile}
                />
              </PrivateRoute>
            }
          />
          <Route path="/upload/*" element={<PrivateRoute isLoggedIn={isLoggedIn}><Upload /></PrivateRoute>} />
        </Routes>
        {/* {showModal && (
          <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
            <TypeformQuestions onSubmit={handleSubmitTypeform} />
          </Modal>
        )} */}
      </div>
    </Router>
  );
}

export default App;
