// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, getAdditionalUserInfo, onAuthStateChanged, signInWithEmailAndPassword, signInWithRedirect, createUserWithEmailAndPassword } from 'firebase/auth';


// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "astreaslabs.co",
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: "G-888CRZFN56",
// };
const firebaseConfig = {
  apiKey: "AIzaSyBlNIUi63cnXss_mMMzgSy1oR1K1cJAPoM",
  authDomain: "sodium-hour-435114-n4.firebaseapp.com",
  projectId: "sodium-hour-435114-n4",
  storageBucket: "sodium-hour-435114-n4.appspot.com",
  messagingSenderId: "550421039180",
  appId: "1:550421039180:web:4d6871d75a33a08bed4ce9",
  measurementId: "G-888CRZFN56"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export { auth, googleProvider, getAdditionalUserInfo, onAuthStateChanged, signInWithEmailAndPassword, signInWithRedirect, createUserWithEmailAndPassword };
